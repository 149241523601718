.itemHeaderWrapper {
    z-index: 10;
    border-radius: 1rem 1rem 0rem 0rem;
    background: linear-gradient(180deg, #2F1C0A 91.44%, rgba(47, 28, 10, 0.00) 100%);
}

.itemHeader {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid var(--Plii-Secondary, #2F1C0A);
    background: #FBEFDA;
}

.itemHeader.highAttentionLevel {
    background: var(--Plii-Red, #FF6632);
}

.itemHeader.familyEventHeader {
    background: #D2F297;
}

.itemHeader.birthdayEventHeader {
    background: #D1B7FF;
}

.itemHeader.reminderHeader {
    background: #D1B7FF;
}

.leftHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.leftHeader .topTitle {
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    align-self: flex-start;
}

.leftHeader .mainTitle {
    margin-top: 1rem;
    color: #2F1C0A;
    font-family: "GT Maru Mega Trial";
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 85%;
    max-width: 4ch;
    /* Ensures text wraps after 3 characters */
    overflow-wrap: break-word;
    align-self: flex-start;
    /* 3.1875rem */
}

/* Bottom Header */
.rightHeader {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    /* Centers the summary */
    align-items: flex-start;
    /* Aligns items to the left */
    /* Ensures vertical alignment with other elements */
    width: 100%;
    /* Ensures it takes up the full width of the container */
}

.rightHeader .attentionHeader {
    position: absolute;
    top: 0;
    right: 0;
}

.hornIconContainer {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    border: 1px solid #2F1C0A;
    background: #FFA929;
}

.hornIcon {
    width: 70%;
    height: 70%;
}

.rightHeader .headerSummary {
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding-left: 1rem;
    /* 1.05rem */
}

.rightHeader .headerSummary p {
    margin: 0;
    line-height: 120%;
}

.headerDivider {
    width: 0.5px;
    align-self: stretch;
    background-color: black;
}


.bottomHeader h3 {
    margin: 0;
    /* Removes any default margins for precise alignment */
    text-align: left;
    /* Ensures the text itself is left-aligned */
}

.sectionTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

/* Divider */
.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 12px;
}