/* Top Header */
.itemHeader {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid var(--Plii-Secondary, #2F1C0A);
    background: #FBEFDA;
}

.itemHeader.highAttentionLevel {
    background-color: #D1B7FF;
}

.leftHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.leftHeader .topTitle {
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    align-self: flex-start;
}

.leftHeader .mainTitle {
    margin-top: 1rem;
    color: #2F1C0A;
    font-family: "GT Maru Mega Trial";
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 85%;
    max-width: 3ch;
    /* Ensures text wraps after 3 characters */
    overflow-wrap: break-word;
    align-self: flex-start;
    /* 3.1875rem */
}

/* Bottom Header */
.rightHeader {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    /* Centers the summary */
    align-items: flex-start;
    /* Aligns items to the left */
    /* Ensures vertical alignment with other elements */
    width: 100%;
    /* Ensures it takes up the full width of the container */
}

.rightHeader .attentionHeader {
    position: absolute;
    top: 0;
    right: 0;
}

.hornIconContainer {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    border: 1px solid #2F1C0A;
    background: #FFA929;
}

.hornIcon {
    width: 70%;
    height: 70%;
}

.rightHeader .headerSummary {
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding-left: 1rem;
    /* 1.05rem */
}

.rightHeader .headerSummary p {
    margin: 0;
    line-height: 120%;
}

.headerDivider {
    width: 0.5px;
    height: 100%;
    margin-bottom: 1rem;
    background-color: black;
}


.bottomHeader h3 {
    margin: 0;
    /* Removes any default margins for precise alignment */
    text-align: left;
    /* Ensures the text itself is left-aligned */
}

.sectionTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
}

/* Divider */
.divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin-bottom: 12px;
}

/* Content */
.content {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #2F1C0A;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    overflow: auto;
    position: relative;
    margin-top: -40px;
    padding-top: 40px;
    clip-path: inset(20px 0 0 0);
    /* 1.05rem */
}

/* UNUSED but kept just in case */

.cardsLeft {
    display: flex;
    padding: 0.375rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115%;
    /* 1.00625rem */
    border-radius: 0.4375rem;
    background: #000;
    margin-left: auto;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 1rem;
    background: #2F1C0A;
    /* Plii Card */
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.50);
}

.section h3 {
    font-family: 'GT Maru Mega Trial';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.section h3.highAttentionLevel {
    color: #FF6632;
}

.section ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.section ul li {
    margin-bottom: 0.5rem;
    align-items: center;
}

.section ul li:last-child {
    margin-bottom: 0;
}

.placeHolderContentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    flex-grow: 1;
}

.finishedIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.finishedIconBackground {
    border-radius: 0.75rem;
    background: #FF6632;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.finishedIcon {
    width: 1.875rem;
    height: 1.875rem;
    flex-shrink: 0;
}

.noMoreCardExplanation {
    color: #FBEFDA;
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.05rem */
}