.instructions {
    margin-top: 4.95rem;
    color: var(--Plii-Secondary, #2F1C0A);
    font-family: Inter;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 3rem;
    /* 1.4875rem */
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
    background: #FFF;
    max-height: 40vh;
    /* Fixed height for the container */
    overflow: auto;
    /* Ensure scrolling only happens inside the table */
    color: #2F1C0A;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
}

.scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
}

.calendarRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.44rem;
    /* 10px */
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #2F1C0A;
    /* 8px */
    min-height: 2rem;
}

.lastRow {
    border-bottom: none;
    padding-bottom: 0.69rem;
}

.calendarInfo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    overflow: hidden;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #2F1C0A;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 1.225rem */
    cursor: pointer;
}

.calendarEmail {
    display: block;
    /* Adjust as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkedEmail {
    max-width: 45vw;
}

.uncheckedEmail {
    max-width: 75vw;
}

.checkboxInput {
    appearance: none;
    width: 0.9375rem;
    height: 0.9375rem;
    flex-shrink: 0;
    background-color: white;
    border-radius: 0.25rem;
    border: 1.5px solid #2F1C0A;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxInput:focus {
    border: 1.5px solid #2F1C0A;
}


.checkboxInput:checked {
    background-color: #2F1C0A;
    /* Change to any color */
    border-color: #2F1C0A;
}

.checkboxInput:checked::after {
    content: url("../../assets/icons/checkMark.svg");
    color: #FFA929;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.62rem;
}