/* Sticky Navbar */
.navbar {
  position: absolute;
  top: 0;
  left: 50%;
  /* Position the navbar relative to the viewport */
  transform: translateX(-50%);
  /* Shift it back by half its width to center */
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.20);
  background: rgba(255, 255, 255, 0.9);
  /* White background for sticky navbar */
  max-width: 100rem;
  /* Cap the width of the container */
  margin: 0 auto;
  /* Center the navbar horizontally */
  box-sizing: border-box;
  align-items: center;
  height: 6.25rem;
  width: 100%;
  flex-shrink: 0;
  z-index: 10;
}

/* Navbar Positioned Over the Hero Section */
.navbar.absolute {
  position: absolute;
  top: 0;
  left: 50%;
  /* Position the navbar relative to the viewport */
  transform: translateX(-50%);
  /* Shift it back by half its width to center */
  background: rgba(255, 255, 255, 0.9);
  /* White background for sticky navbar */
  color: #fff;
  box-shadow: none;
  /* No shadow in absolute state */
}

/* Sticky Navbar Style */
.navbar.sticky {
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  /* White background for sticky navbar */
  color: #333;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for sticky state */
}

.navbarContent {
  margin: 0 auto;
  /* Centers content horizontally */
  padding-left: 5rem;
  /* Left padding for the logo */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  padding-top: 1.25rem;
}

.logoContent {
  height: 3.75rem;
  width: 5.617rem;
}

.navButton {
  display: inline-flex;
  /* Aligns content inside the button */
  justify-content: center;
  /* Centers text/content horizontally */
  padding: 0.84375rem 2.875rem;
  align-items: center;
  /* Centers text/content vertically */
  border-radius: 5rem;
  /* Rounded corners */
  background: #FF6632;
  /* Button background color */
  color: #fff;
  /* Button text color */
  font-size: 1rem;
  /* Adjust font size as needed */
  border: none;
  /* Removes default button border */
  cursor: pointer;
  /* Changes the cursor to pointer on hover */
  margin-top: 1.5rem;
  /* Top margin as per your requirement */
  margin-right: 5rem;
  /* Right margin as per your requirement */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional shadow for depth */
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* transition: background 0.2s ease; Smooth hover transition */
}

/* Hover Effect */
.navButton:hover {
  background: #e55429;
  /* Slightly darker shade on hover */
}

/* Hero Section */
.hero {
  position: relative;
  background: url('../assets/playdate.jpg') lightgray center / cover no-repeat;
  width: 100%;
  /* Full width */
  max-width: 100rem;
  /* Cap the width of the container */
  min-height: 47.875rem;
  /* Height based on your design */
  margin: 0 auto;
  /* Center the capped container */
  padding: 0;
  background-size: cover;
  /* Stretch the background image */
  background-position: center;
  /* Center the image */
  color: #fff;
  text-align: center;
  overflow: hidden;
}


.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* Increased overlay opacity */
  z-index: 1;
}

.heroContent {
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: 3.75rem;
  margin-top: 20.38rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.hero p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  margin: 0.75rem 14.88rem;
}

.heroButton {
  display: inline-flex;
  /* Aligns content inside the button */
  justify-content: center;
  /* Centers text/content horizontally */
  padding: 0.84375rem 3.125rem;
  align-items: center;
  /* Centers text/content vertically */
  border-radius: 5rem;
  /* Rounded corners */
  background: #FF6632;
  /* Button background color */
  color: #fff;
  /* Button text color */
  font-size: 1rem;
  /* Adjust font size as needed */
  border: none;
  /* Removes default button border */
  cursor: pointer;
  /* Changes the cursor to pointer on hover */
  margin-top: 2rem;
  /* Top margin as per your requirement */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.60);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.heroButton:hover {
  background: #e55429;
  /* Slightly darker shade on hover */
}

.privacyNote {
  margin-top: 1rem;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 1.225rem */
}

section {
  max-width: 100rem;
  margin: 0 auto;
}

.features {
  display: grid;
  /* Use CSS Grid for layout */
  grid-template-columns: repeat(1, 1fr);
  /* Default: 1 card per row */
  gap: 1.5rem;
  /* Spacing between cards */
  padding: 6.25rem 5rem;
  /* Section padding */
  max-width: 80rem;
  /* Cap the width */
  margin: 0 auto;
  /* Center the section */
}

.iconContainer {
  width: 6.25rem;
  /* Match Figma width */
  height: 6.25rem;
  background-color: #000;
  /* Black background */
  border-radius: 1.25rem;
  /* Match Figma border radius */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  /* Subtle shadow */
}

/* Feature Card */
.featureCard {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: left;
  /* Center content */
  text-align: left;
  /* Center text */
  max-width: 18.875rem;
  max-height: 21rem;
  padding: 1.5rem;
  padding-bottom: 0;
  flex-shrink: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  border-radius: 1.25rem;
  background: #FFF;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

/* Feature Icon */
.featureIcon {
  margin-top: 1rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-bottom: 1.12rem;
}

/* Feature Title */
.featureCard h3 {
  font-size: 1.5rem;
  /* Title size */
  font-weight: 600;
  max-width: 15.875rem;
  margin: 0;
  padding: 0;
  line-height: 120%;
  color: #333;
  /* Dark text color */
}

/* Feature Description */
.featureCard p {
  font-size: 1.125rem;
  max-width: 15.875rem;
  line-height: 120%;
  font-weight: 500;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  text-align: left;
  /* Center text */
  color: #2E1800;
  /* Subtle text color */
}

/* Signup Section */
.signup {
  border-radius: 6.25rem 6.25rem 0rem 0rem;
  background: linear-gradient(180deg, #FFB033 -19.33%, #FF6632 100%);
  width: 100%;
  max-width: 100rem;
  max-height: 25.4375rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 0;
}

.signup .header {
  color: #FFF;
  text-align: center;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  /* 3.3rem */
  letter-spacing: -0.09rem;
}

.signup .prompt {
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 2.1rem */
}

.signup .signupContainer {
  border-radius: 5rem;
  border: 1.5px solid #FFF;
  max-width: 34rem;
  max-height: 3.25rem;
  margin: 0 auto;
  margin-top: 3rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  justify-content: center;
  padding-top: 0;
}

.signupContainer input[type='email'] {
  padding-left: 1.75rem;
  background: transparent;
  color: #FFF;
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
}

.signupContainer input::placeholder {
  color: #FFF;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 1.575rem */
}

.signupContainer input:focus {
  color: #FFF;
  /* Ensure the cursor matches the text color */
  border: none;
  /* No border on focus */
  outline: none;
  /* No focus outline */
}

.signupButton {
  width: 8.4375rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 3.125rem;
  background: #FFF;
  color: #FF6632;
  border: none;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 1.575rem */
  margin-right: 0.37rem;
  margin-top: 0.37rem;
  margin-bottom: 0.37rem;
}

.signupButton:hover {
  background-color: #FFD3C1;
}

/* Footer */
footer {
  flex-grow: 0;
  background-color: #333;
  color: #fff;
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
  max-height: 3.75rem;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 5rem;
  padding-right: 5rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 1.225rem */
  box-sizing: border-box;
  display: flex;
  /* Use Flexbox for layout */
  justify-content: space-between;
  /* Push left and right content apart */
  align-items: center;
  /* Vertically center content */
}

/* Footer Left (plii LLC) */
.footerLeft {
  text-align: left;
}

/* Footer Right (Links) */
.footerRight {
  text-align: right;
}

.footerRight a {
  color: #fff;
  /* Ensure links are white */
  text-decoration: none;
  /* Remove underline */
  margin-left: 0.25rem;
  /* Add spacing between links */
  margin-right: 0.25rem;
}

.footerRight a:hover {
  text-decoration: underline;
  /* Optional hover effect */
}

@media (min-width: 280px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row for small screens */
  }
}

@media (max-width: 992px) {
  .hero h1 {
    margin-top: 4.38em;
  }

  .hero p {
    margin: 0.75rem 2.88rem;
  }
}

@media (min-width: 992px) {
  .features {
    grid-template-columns: repeat(4, 1fr);
    /* 4 cards per row for larger screens */
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    position: sticky;
    /* Sticky only on mobile */
    top: 0;
    left: 0;
    /* Reset centering for mobile */
    transform: none;
    /* Disable centering transform */
    background: rgba(255, 255, 255, 0.9);
    /* Add a solid background */
    color: #333;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Add subtle shadow */
    padding: 0.5rem 1rem;
    /* Reduce padding for small screens */
    display: flex;
    justify-content: center;
  }

  .navbarContent {
    padding: 0;
    /* Remove any extra padding within the navbar content */
    margin: 0;
    /* Remove default margins */
    justify-content: space-between;
    /* Align logo and button properly */
    flex-direction: row;
    width: 100%;
    /* Ensure horizontal layout remains */
  }

  .logoContent {
    width: 4.5rem;
    /* Reduce logo size for smaller screens */
    height: auto;
    padding-top: 0;
    /* Remove extra padding above the logo */
  }

  .logoContainer {
    padding-top: 0.15rem;
  }

  .navButton {
    margin: 0;
    /* Remove margin issues */
    padding: 0.5rem 1.25rem;
    /* Adjust padding for smaller button size */
    font-size: 0.875rem;
    /* Adjust button font size for better fit */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    /* Optional: Lighter shadow for smaller screens */
  }

  .hero {
    min-height: 25vh;
    /* Reduce height further on smaller screens */
  }

  .hero h1 {
    margin-top: 2.5rem;
    font-size: 1.75rem;
  }

  .heroButton {
    display: none;
    /* Hide the button on screens smaller than 768px */
  }

  .hero p {
    font-size: 1rem;
  }

  .hero p {
    margin: 1.5rem 5rem;
  }

  footer {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.5rem;
  }

  .features {
    padding: 2.5rem 1.5rem;
    /* Section padding */
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row for small screens */
  }

  .featureCard h3 {
    font-size: 1.125rem;
  }

  .featureCard p {
    font-size: 0.875rem;
    margin-bottom: 1.2rem;
  }

  .signup {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  .signup .header {
    font-size: 1.75rem;
  }

  .signup .prompt {
    font-size: 0.875rem;
  }

  .signup .signupContainer {
    margin-top: 1.5rem;
    margin: 0 auto;
  }

  .signupContainer input[type='email'] {
    font-size: 1rem;
  }

  .signupContainer input::placeholder {
    font-size: 1rem;
  }

  .signupButton {
    font-size: 1rem;
  }

  .privacyNote {
    margin-top: 1rem;
    font-size: 0.7rem;
  }
}

/* Responsive Adjustments */
@media (max-width: 430px) {
  .hero {
    min-height: 35vh;
    /* Reduce height further on smaller screens */
  }
}

/* Responsive Adjustments */
@media (max-width: 370px) {
  .hero {
    min-height: 50vh;
    /* Reduce height further on smaller screens */
  }
}